import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import "./styles.scss";

function CapabilityTopic({
	heading,
	description,
	caseStudy,
	caseStudyLinkText,
	images,
	capability_article,
	index,
}) {
	return (
		<div
			className={`topic ${index % 2 ? "topic--odd" : "topic--even"} ${
				images?.length ? "topic--has-images" : "topic--no-images"
			} `}
		>
			<div className="topic__info">
				<h2 className="topic__heading">{heading}</h2>
				{description && (
					<div
						className="markdown"
						dangerouslySetInnerHTML={{
							__html: description.childMarkdownRemark.html,
						}}
					/>
				)}
				{capability_article && (
					<div className="topic__processes">
						<div className="topic__processes-heading">Processes:</div>
						<ul className="topic__processes-list">
							{/* Removing links to the individual process pages. Content for these pages will be added in a later phase */}
							{/* {capability_article.map((article, index) => {
								return (
									<li key={index}>
										<Link to={`/${article.slug}`}>{article.title}</Link>
									</li>
								);
							})} */}
							{capability_article.map((article, index) => {
								return <li key={index}>{article.title}</li>;
							})}
						</ul>
					</div>
				)}
				{caseStudy && caseStudyLinkText && (
					<Link className="topic__case-study" to={`/${caseStudy.slug}`}>
						<div>{caseStudyLinkText}</div>
						<div>
							<HiOutlineArrowNarrowRight aria-hidden="true" size="1.5em" />
						</div>
					</Link>
				)}
			</div>
			{images?.length && (
				<div className="topic__images">
					{images.map((image, index) => {
						const imageData = getImage(image);

						return (
							<div className="topic__image" key={index}>
								<GatsbyImage
									image={imageData}
									alt={image?.description || image?.title}
								/>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
}

export default CapabilityTopic;

CapabilityTopic.propTypes = {
	heading: PropTypes.string,
	description: PropTypes.shape({
		childMarkdownRemark: PropTypes.shape({
			html: PropTypes.string,
		}),
	}),
	caseStudy: PropTypes.shape({
		slug: PropTypes.string,
	}),
	caseStudyLinkText: PropTypes.string,
	index: PropTypes.number,
	capability_article: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			slug: PropTypes.string,
		})
	),
	images: PropTypes.arrayOf(
		PropTypes.shape({
			description: PropTypes.string,
			gatsbyImageData: PropTypes.object,
			title: PropTypes.string,
		})
	),
};
