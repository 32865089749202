import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import SearchEngineOptimization from "../../components/SearchEngineOptimization";
import CapabilityTopic from "../../components/CapabilityTopic";
import "./styles.scss";

function CapabilitiesPage({ data: { capability } }) {
	const { title, topics } = capability;

	return (
		<>
			<Layout>
				<SearchEngineOptimization title={title} />
				<div className="capabilities-page">
					<div className="capabilities-page__hero">
						<div className="capabilities-page__subtitle">Capabilities</div>
						<h1 className="capabilities-page__title">{title}</h1>
					</div>
					{topics && (
						<section className="section">
							<div className="container">
								{topics.map((topic, index) => {
									const {
										heading,
										description,
										images,
										caseStudy,
										caseStudyLinkText,
										capability_article,
									} = topic;
									return (
										<CapabilityTopic
											key={index}
											heading={heading}
											description={description}
											images={images}
											caseStudy={caseStudy}
											caseStudyLinkText={caseStudyLinkText}
											capability_article={capability_article}
											index={index}
										/>
									);
								})}
							</div>
						</section>
					)}
				</div>
			</Layout>
		</>
	);
}

export const query = graphql`
	query($slug: String!) {
		capability: contentfulCapability(slug: { eq: $slug }) {
			title
			topics {
				heading
				images {
					gatsbyImageData(
						placeholder: NONE
						formats: [AUTO, WEBP]
						quality: 100
					)
					description
					title
				}
				description {
					childMarkdownRemark {
						html
					}
				}
				caseStudyLinkText
				caseStudy {
					slug
				}
				capability_article {
					title
					slug
				}
			}
		}
	}
`;

export default CapabilitiesPage;
